import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useCallback } from 'react';
// hooks
import { useLocalStorage, getStorage } from 'src/hooks/use-local-storage';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// _mock
import { PRODUCT_CHECKOUT_STEPS } from 'src/_mock/_product';
// components
import { useSnackbar } from 'src/components/snackbar';
//
import { axiosInstanceNew } from 'src/utils/axios';
import { CheckoutContext } from './checkout-context';

// ----------------------------------------------------------------------

export const STORAGE_KEY = 'checkout_HARAASH_01071960';

const initialState = {
  activeStep: 0,
  items: [],
  subTotal: 0,
  total: 0,
  discount: 0,
  totalDiscount: 0,
  totalItems: 0,
  price: 1,
};

export function CheckoutProvider({ children }) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { state, update, reset } = useLocalStorage(STORAGE_KEY, initialState);

  const { isAuthenticated } = useSelector((reduxState) => reduxState.auth);

  useEffect(() => {
    const fetchAdPricingDetails = async () => {
      try {
        const res = await axiosInstanceNew('/api/client/settings/pricing-details');
        const { price, discount } = res.data;
        // Update the state with the fetched pricing details
        update('price', price || 0.5);
        update('discount', discount || 0.5);
      } catch (error) {
        console.log('Error fetching ad pricing details:', error);
      }
    };

    if (isAuthenticated) {
      fetchAdPricingDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const onGetCart = useCallback(() => {
    const totalItems = state.items.reduce((total, item) => total + item.quantity, 0);
    const subTotal = state.items.reduce(
      (total, item) => total + item.advertDuration * state.price,
      0
    );
    const totalDiscount = state.items.reduce(
      (total, item) => total + item.advertDuration * state.discount,
      0
    );

    update('totalDiscount', totalDiscount);
    update('subTotal', subTotal);
    update('totalItems', totalItems);
    update('total', subTotal - state.totalDiscount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.items,
    state.activeStep,
    state.billing,
    state.discount,
    state.totalDiscount,
    state.subTotal,
    state.price,
  ]);

  useEffect(() => {
    const restored = getStorage(STORAGE_KEY);

    if (restored) {
      onGetCart();
    }
  }, [onGetCart]);

  const onAddToCart = useCallback(
    (newItem) => {
      const dateNow = Date.now();

      if (newItem.publish === true || newItem.expiresAt > dateNow) {
        return enqueueSnackbar('Product is already pubished', { variant: 'error' });
      }

      const updatedItems = [...state.items];

      if (!state.items.some((item) => item._id === newItem._id)) {
        updatedItems.push({ ...newItem, advertDuration: newItem.advertDuration || 1 });
        update('items', updatedItems);
      }

      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update, state.items]
  );

  const onDeleteCart = useCallback(
    (itemId) => {
      const updatedItems = state.items.filter((item) => item._id !== itemId);

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const onDeleteCartItems = useCallback(
    (itemIds) => {
      // Expect itemIds to be an array of IDs
      const updatedItems = state.items.filter((item) => !itemIds.includes(item._id));

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const onBackStep = useCallback(() => {
    update('activeStep', state.activeStep - 1);
  }, [update, state.activeStep]);

  const onNextStep = useCallback(() => {
    update('activeStep', state.activeStep + 1);
  }, [update, state.activeStep]);

  const onGotoStep = useCallback(
    (step) => {
      update('activeStep', step);
    },
    [update]
  );

  const onIncreaseQuantity = useCallback(
    (itemId) => {
      const updatedItems = state.items.map((item) => {
        console.log('itemId', item.id === itemId);
        if (item._id === itemId) {
          return {
            ...item,
            advertDuration: item.advertDuration + 1,
          };
        }
        return item;
      });

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const onDecreaseQuantity = useCallback(
    (itemId) => {
      const updatedItems = state.items.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            advertDuration: item.advertDuration - 1,
          };
        }
        return item;
      });

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const completed = state.activeStep === PRODUCT_CHECKOUT_STEPS.length;

  // Reset
  const onReset = useCallback(() => {
    if (completed) {
      reset();
      router.replace(paths.product.root);
    }
  }, [completed, reset, router]);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      completed,
      //
      onAddToCart,
      onDeleteCart,
      onDeleteCartItems,
      //
      onIncreaseQuantity,
      onDecreaseQuantity,
      //
      onBackStep,
      onNextStep,
      onGotoStep,
      //
      onReset,
    }),
    [
      completed,
      onAddToCart,
      onDeleteCartItems,
      onBackStep,
      onDecreaseQuantity,
      onIncreaseQuantity,
      onDeleteCart,
      onGotoStep,
      onNextStep,
      onReset,
      state,
    ]
  );

  return <CheckoutContext.Provider value={memoizedValue}>{children}</CheckoutContext.Provider>;
}

CheckoutProvider.propTypes = {
  children: PropTypes.node,
};
